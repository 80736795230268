import { getCmsContent } from '@graphql/operations/cmsContent';
import { getQueryVariables } from '@utilities/cms';

/**
 * Get the CMS page middlware.
 */
export const cmsPageMiddleware = async ({ app, route }) => {
    // We await fetch here to briefly halt the rendering when a route update occurs between CMS pages
    // otherwise the user will see a messy FOUC and potential missing component race-condition errors
    const apollo = app.apolloProvider.defaultClient;

    const isPreviewing = app?.$previewMode?.isPreviewing;

    // If the route is already cached this is nearly instantaneous, otherwise it
    // will await the content to prevent missing component race-condition errors
    await apollo.query({
        query: getCmsContent,
        variables: getQueryVariables(route, isPreviewing),
        fetchPolicy: isPreviewing ? 'network-only' : 'cache-first',
    });
};
