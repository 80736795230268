<template>
    <div v-if="page">
        <HomepageMastheadFullBleedFourPanel v-if="getBlockByName('homepageBannerCardLayout')" />

        <template>
            <HomePageCustomContentBlock v-if="getBlockByName('customContentBlock')" />
            <CategoryHeadline v-if="getBlockByName('categoryHeadings')" />
            <ShopByCategories
                v-if="getBlockByName('shopBySection')"
                data-automation="shop_by_categories"
            />
            <ProductCarouselFilterable
                v-if="getBlockByName('filterableProductCarousel')"
                hide-bullets
                data-automation="home_product_carousel"
            />
            <ShoppingFeatures v-if="getBlockByName('shoppingFeatures', 'cards.0')" />
            <HomepageContactUsValuePropositionGrid
                v-if="getBlockByName('valuePropositions') && getBlockByName('contactUs')"
                v-bind:page="page"
            />
            <ProductRecentlyViewedProducts
                is-blue-background
                v-bind:class="$style.recentlyViewedProducts"
            />
            <HomePageRecentSearches />
            <CustomerTestimonials v-if="getBlockByName('customerTestimonials')" />
            <ResourcesCarouselFilterable v-if="getBlockByName('filterableResourcesCarousel')" />
            <BannerManufacturersHeadline v-if="getBlockByName('manufacturerCalloutHeading')" />
            <BannerManufacturersCentered v-if="getBlockByName('manufacturerCallout')" />
        </template>
    </div>
</template>

<script>
    import { cmsPageMiddleware } from '@utilities/cms/page/cmsPageMiddleware';
    import { getCmsPageMetaData } from '@utilities/cms/page/getCmsPageMetaData';
    import { getDefaultConfig, getBlock } from '@utilities/cms';
    import { hydrateWhenVisible, hydrateNever } from 'vue-lazy-hydration';
    import {
        getUserInterfaceState,
        setUserInterfaceState,
    } from '@graphql/operations/userInterface';

    export default {
        /**
         * The Vue Apollo special option for queries in Vue components.
         *
         * @link https://apollo.vuejs.org/guide/apollo/
         */
        apollo: {
            page: {
                ...getDefaultConfig(),
                result() {
                    this.preloadDynamicMastheadComponents();
                },
            },
            hasInteracted: {
                query: getUserInterfaceState,
                update: ({ userInterface }) => userInterface.hasInteracted,
            },
        },

        /**
         * Set the Nuxt middleware for a specific page of the application.
         *
         * @link https://nuxtjs.org/api/pages-middleware
         */
        middleware: cmsPageMiddleware,

        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            HomepageMastheadFullBleedFourPanel: () =>
                import('@components/HomepageMastheadFullBleedFourPanel'),
            CategoryHeadline: hydrateNever(() => import('@components/CategoryHeadline')),
            ShopByCategories: () => import('@components/ShopByCategories'),
            ShoppingFeatures: hydrateWhenVisible(() => import('@src/components/ShoppingFeatures')),
            BannerManufacturersHeadline: hydrateNever(() =>
                import('@components/BannerManufacturersHeadline'),
            ),
            BannerManufacturersCentered: hydrateWhenVisible(() =>
                import('@components/BannerManufacturersCentered'),
            ),
            CustomerTestimonials: hydrateWhenVisible(() =>
                import('@components/CustomerTestimonials'),
            ),
            ProductCarouselFilterable: hydrateWhenVisible(() =>
                import('@src/components/ProductCarouselFilterable'),
            ),
            HomePageCustomContentBlock: hydrateWhenVisible(() =>
                import('@components/HomePageCustomContentBlock'),
            ),
            ResourcesCarouselFilterable: hydrateWhenVisible(() =>
                import('@components/ResourcesCarouselFilterable'),
            ),
            ProductRecentlyViewedProducts: () =>
                import('@components/ProductRecentlyViewedProducts'),
            HomepageContactUsValuePropositionGrid: hydrateWhenVisible(() =>
                import('@components/HomepageContactUsValuePropositionGrid'),
            ),
            HomePageRecentSearches: hydrateWhenVisible(() =>
                import('@components/HomePageRecentSearches'),
            ),
        },

        /**
         * Nuxt method for populating server data on initial load and client data on subsequent loads.
         *
         * @link https://nuxtjs.org/api/pages-fetch
         */
        async asyncData({ app, route, $config }) {
            const jsonLd = [
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'http://schema.org',
                        '@type': 'Organization',
                        url: 'http://www.cpap.com',
                        logo: $config.imageHostBaseUrl + '/meta/logo.png',
                        description:
                            'CPAP.com is a family owned and operated business dedicated to providing affordable sleep apnea equipment and resources to those who need CPAP therapy.',
                        sameAs: [
                            'https://www.facebook.com/CPAPDotCom',
                            'https://twitter.com/cpapdotcom',
                            'https://www.youtube.com/channel/UCY4LuRO-IT9_zdxa3ceA4Sg',
                            'https://www.pinterest.com/CPAPDotCom/',
                        ],
                        foundingDate: '1999',
                        contactPoint: [
                            {
                                '@type': 'ContactPoint',
                                telephone: '+1-800-356-5221',
                                faxNumber: '+1-866-353-2727',
                                '@id': 'https://www.cpap.com/contact',
                                contactType: 'customer service',
                            },
                        ],
                    },
                },
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'http://schema.org',
                        '@type': 'WebSite',
                        url: 'https://www.cpap.com/',
                        keywords:
                            `cpap,cpaps,cpap machine,cpap machines,cpap mask,cpap masks,cpap supplies,cpap supply,resmed,` +
                            `respironics,puritan benet,fisher & paykel,cpap.com,filters,tubing,cushions,headgear,devilbiss,somnetics`,
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: 'https://www.cpap.com/search?query={search_query}',
                            'query-input': 'required name=search_query',
                        },
                    },
                },
            ];

            const data = {
                jsonLd,
            };

            // Determine if this is the server build
            if (process.server) {
                const apollo = app.apolloProvider.defaultClient;
                const [{ fetchStaticBlock }, { getAbsoluteUrlPath }] = await Promise.all([
                    import('@utilities/cms'),
                    import('@utilities/getAbsoluteUrlPath'),
                ]);

                const [
                    bannerData,
                    { default: probeImageSize },
                    { default: _flow },
                    { default: _camelCase },
                    { default: _upperFirst },
                ] = await Promise.all([
                    // Fetch the advanced homepage banner data
                    fetchStaticBlock(apollo, route, 'homepageBannerCardLayout', {
                        isPreviewing: app.$previewMode?.isPreviewing,
                    }),
                    import('probe-image-size'),
                    import('lodash/flow'),
                    import('lodash/camelCase'),
                    import('lodash/upperFirst'),
                ]);

                const firstBanner = bannerData.carouselMainSection?.[0];

                if (firstBanner) {
                    const pascalCase = _flow(_camelCase, _upperFirst);
                    const heroImageTypes = ['mobile', 'tablet', 'desktop'];
                    const images = firstBanner.bannerImage?.[0]?.images || [];

                    try {
                        const imageSizingProbes = heroImageTypes.map(type => {
                            const { imageUrl } =
                                images.find(({ _meta: { codename } }) =>
                                    codename.toLowerCase().includes(type),
                                ) || {};

                            // In the case the path is relative, let's replace to be a full url so we can probe.
                            const hasImageUrlScheme = imageUrl.startsWith('http');
                            const normalizedImageUrl = getAbsoluteUrlPath(imageUrl);
                            const fullUrl = hasImageUrlScheme
                                ? normalizedImageUrl
                                : $config.imageHostBaseUrl + normalizedImageUrl;

                            return imageUrl ? probeImageSize(fullUrl) : null;
                        });

                        // Use the image size probes to get the remote image meta data
                        // This will not impact subsequent page loads because the homepage response
                        // is cached and returned from the edge server on future requests
                        const imageSizes = await Promise.all(imageSizingProbes);

                        imageSizes.forEach((image, index) => {
                            if (image?.width && image?.height) {
                                // Set the aspect ratio for mobile if a width and height were discovered
                                const { width, height } = image;

                                const apolloKey = pascalCase(heroImageTypes[index]);

                                data[`homepageBanner${apolloKey}Width`] = width;
                                data[`homepageBanner${apolloKey}Height`] = height;
                            }
                        });
                    } catch (error) {
                        const { logError } = await import('@utilities/logError');

                        logError(error);
                    }
                }
            }

            return data;
        },

        /**
         * Watch properties or data for changes and perform an action when an update occurs.
         *
         * @link https://vuejs.org/v2/guide/computed.html#Watchers
         */
        watch: {
            hasInteracted(hasInteracted) {
                if (hasInteracted) {
                    // This is an optimization to defer newsletter modal
                    // logic until after priority main thread execution
                    this.checkForNewsletterPromotion();
                }
            },
        },

        /**
         * This method logic will be run immediately after the Vue instance is created.
         *
         * @link https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
         */
        created() {
            this.storeHomepageBannerImageDimensions();
        },

        /**
         * Non-cached methods that are used by the Vue instance and template.
         *
         * @type Object ES6 methods
         */
        methods: {
            /**
             * Check if the current page has the optional newsletter modal promotion block.
             */
            async checkForNewsletterPromotion() {
                const blocks = this.page?.blocks || [];

                if (blocks.map(({ name }) => name).includes('optionalNewsletterModalPromotion')) {
                    const { default: initiateNewsletterPromotionDialog } = await import(
                        '@utilities/initiateNewsletterPromotionDialog'
                    );

                    initiateNewsletterPromotionDialog(this);
                }
            },

            /**
             * Return the page block for a matching module based on name if it exists.
             *
             * @param {String} name The Kentico module name.
             * @param {String} path The Kentico module nested path.
             *
             * @return {Object}
             */
            getBlockByName(name, path) {
                return getBlock(this.page, name, path);
            },

            /**
             * Store the homepage banner image dimensions determined from the server.
             */
            storeHomepageBannerImageDimensions() {
                // Add the ratio to Apollo local cache
                this.$apollo.mutate({
                    mutation: setUserInterfaceState,
                    variables: this,
                });
            },

            /**
             * The masthead component chunks must be prefetched to avoid a FOUC during hydration
             * when they are dynamically imported within the individual child components.
             */
            async preloadDynamicMastheadComponents() {
                if (process.client) {
                    await Promise.all([
                        import('@components/Framework/AppImage'),
                        import('@components/Framework/AppLink'),
                        import('@components/HomepageMastheadCallToAction'),
                        import('@fortawesome/fontawesome-pro/svgs/solid/arrow-right.svg'),
                    ]);
                }
            },
        },

        /**
         * Nuxt page meta data property for the page component.
         *
         * @link https://nuxtjs.org/api/pages-head
         */
        head: getCmsPageMetaData,
    };
</script>

<style module>
    .recentlyViewedProducts {
        min-height: 479px;
    }
</style>
