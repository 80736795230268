import { getPageMetaData } from '@utilities/pageMetaData';

/**
 * Get the CMS page head meta data.
 *
 * Note: Do not use an arrow function.
 *
 * @return {object}
 */
export function getCmsPageMetaData () {
    // Fetch the page SEO meta data
    const { meta } = this.page || {};

    if (!meta) {
        return {};
    }

    const data = getPageMetaData(this, {
        title: meta.meta_title,
        description: meta.meta_description,
        canonical: meta.canonical_url,
        robots: meta.robots,
    });

    if (this.jsonLd) {
        data.script = this.jsonLd;
    }

    return data;
};
