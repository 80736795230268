import { getAbsoluteUrlPath } from '@utilities/getAbsoluteUrlPath';

/**
 * Creates a standardized default page meta data object for Nuxt vue-meta.

 * @param  {Object} vm                  The controller view-model instance.
 * @param  {String} options.title       The page meta title.
 * @param  {String} options.description The page meta description.
 * @param  {String} options.canonical   The page meta canonical.
 * @param  {String} options.robots      The page meta robots commands for crawlers.
 *
 * @return {Object}
 */
export const getPageMetaData = (vm, {
    title, description, canonical, robots,
}) => {
    const data = {};
    const meta = [];
    const link = [];

    // Determine if the page title is defined
    if (title) {
        const normalizedTitle = title.trim();

        data.title = normalizedTitle;

        // This is the default. It can be overridden with a spread operation.
        meta.push({
            hid: 'og:title',
            property: 'og:title',
            content: normalizedTitle,
        });

        meta.push({
            hid: 'twitter:title',
            name: 'twitter:title',
            content: normalizedTitle,
        });
    }

    // Determine if the page description is defined
    if (description) {
        const normalizedDescription = description.trim();

        meta.push({
            hid: 'description',
            name: 'description',
            content: normalizedDescription,
        });

        // This is the default. It can be overridden with a spread operation.
        meta.push({
            hid: 'og:description',
            property: 'og:description',
            content: normalizedDescription,
        });

        meta.push({
            hid: 'twitter:description',
            name: 'twitter:description',
            content: normalizedDescription,
        });
    }

    // Determine if the canonical URL is defined
    // Do not add canonical to `noindex` pages per SEO instructions
    if (canonical && !(robots || '').includes('noindex')) {
        const { baseUrl } = vm?.$config || {};
        const hasBaseUrlIncluded = Boolean(baseUrl && canonical.includes(baseUrl));
        const normalizedCanonical = getAbsoluteUrlPath(canonical);
        const canonicalHref = hasBaseUrlIncluded ? normalizedCanonical : (baseUrl + normalizedCanonical);

        link.push({
            hid: 'canonical',
            rel: 'canonical',
            // Remove any whitespace and trailing slashes
            href: canonicalHref.trim().replace(/\/+$/, ''),
        });
    }

    // Determine if the robots crawler data is set
    if (robots) {
        meta.push({
            hid: 'robots',
            name: 'robots',
            content: robots.trim(),
        });
    }

    return {
        ...data,
        ...(meta.length ? { meta } : {}),
        ...(link.length ? { link } : {}),
    };
};

/**
 * Helper method for getting standardized FAQ SEO schema LD+JSON meta data.
 *
 * @param {array} faqs The collection of FAQs.
 *
 * @returns {object}
 */
export const getFaqSchemaMetaData = faqs => ({
    type: 'application/ld+json',
    json: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqs.map(({ answer, question }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: answer,
            },
        })),
    },
});
